@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom markdown styling */
.markdown-content h1 {
  @apply text-3xl font-bold mt-8 mb-4;
  scroll-margin-top: 2rem;
}

.markdown-content h2 {
  @apply text-2xl font-bold mt-6 mb-3;
  scroll-margin-top: 2rem;
}

.markdown-content h3 {
  @apply text-xl font-bold mt-5 mb-2;
  scroll-margin-top: 2rem;
}

.markdown-content p {
  @apply my-4;
}

.markdown-content ul {
  @apply list-disc pl-8 my-4;
}

.markdown-content ol {
  @apply list-decimal pl-8 my-4;
}

.markdown-content a {
  @apply text-blue-600 hover:underline;
}

.markdown-content blockquote {
  @apply pl-4 border-l-4 border-gray-300 my-4 italic;
}

.markdown-content code {
  @apply bg-gray-100 px-1 py-0.5 rounded;
}

.markdown-content pre {
  @apply bg-gray-100 p-4 rounded overflow-x-auto my-4;
}
